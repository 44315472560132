<mat-drawer-container class="side-menu">
  <mat-drawer #drawer mode="side" opened>
    <p>MENU</p>

    <mat-accordion>
      <mat-expansion-panel #panelRef hideToggle>
        <mat-expansion-panel-header (click)="preventPanelExpansion($event)" routerLink="/">
          <mat-panel-title>
            <mat-icon>home</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Dashboard
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>people_outline</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            KYC
          </mat-panel-description>
        </mat-expansion-panel-header>
       <ul>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/kycs">KYC Application</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-photos">Profile Picture</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-users">List of Users</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/create-super-agent">Create Super Agent</a></li>
       </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>credit_card</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Cash-in
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-usercashins">List of Cash-Ins</a></li>
          <li *ngIf="user && user.admin"><a routerLink="/admin-cash-in">Cash-In</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>credit_card</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Cash-out
          </mat-panel-description>
        </mat-expansion-panel-header>
       <ul>
        <li *ngIf="user && user.admin"><a routerLink="/admin-cash-out">Cash-Out</a></li>
       </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>store</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Biller
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li *ngIf="user && user.admin"><a routerLink="/create-biller">Create Biller</a></li>
          <li *ngIf="user && user.admin"><a routerLink="/create-biller-category">Create Biller Category</a></li>
          <li *ngIf="user && user.admin"><a routerLink="/billers">List of Billers</a></li>
          <li *ngIf="user && user.admin"><a routerLink="/biller-categories">Biller Categories</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>home</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Support
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-support">Support</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>home</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            Tables
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-bookings">Booking List</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-payments">Payments List</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-transactions">Transactions List</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>home</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            To Be Sorted
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li><a routerLink="/">Dashboard</a></li>
          <li><a routerLink="/create-booking">Send Cash</a></li>
          <li><a routerLink="/cash-in">Cash-In</a></li>
          <li><a routerLink="/pay-bill">Pay Bill</a></li>
          <li><a routerLink="/cash-out">Cash-Out</a></li>
          <li><a routerLink="/buy-load">Buy Load</a></li>
          <li><a routerLink="/bookings">Bookings</a></li>
          <li><a routerLink="/create-payment">Schedule Payments</a></li>
          <li><a routerLink="/payments">Payments</a></li>
          <li *ngIf="user && user.agent"><a routerLink="/offers">Offers</a></li>
          <li><a routerLink="/super-agents">Super Agents</a></li>
          <li><a routerLink="/selfie">Selfie</a></li>
          <li><a routerLink="/create-kyc">Create KYC</a></li>
          <li><a routerLink="/agents">Agents</a></li>
          <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-totals">Totals</a></li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

  </mat-drawer>
  <!-- <mat-drawer-content *ngIf="view == 'mobile'">
    <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
  </mat-drawer-content> -->
</mat-drawer-container>

<!--
<ul class="list-container">
  <li><a routerLink="/">Dashboard</a></li>
  <li><a routerLink="/create-booking">Send Cash</a></li>
  <li><a routerLink="/cash-in">Cash-In</a></li>
  <li><a routerLink="/pay-bill">Pay Bill</a></li>
  <li><a routerLink="/cash-out">Cash-Out</a></li>
  <li><a routerLink="/buy-load">Buy Load</a></li>
  <li><a routerLink="/bookings">Bookings</a></li>
  <li><a routerLink="/create-payment">Schedule Payments</a></li>
  <li><a routerLink="/payments">Payments</a></li>
  <li *ngIf="user && user.agent"><a routerLink="/offers">Offers</a></li>
  <li><a routerLink="/super-agents">Super Agents</a></li>
  <li><a routerLink="/selfie">Selfie</a></li>
  <li><a routerLink="/create-kyc">Create KYC</a></li>
  <li><a routerLink="/agents">Agents</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/admin-cash-in">Admin Cash-In</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/admin-cash-out">Admin Cash-Out</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/kycs">Manager KYC</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-bookings">Manager Bookings</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-payments">Manager Payments</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-usercashins">Manager User Cash-Ins</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-transactions">Manager Transactions</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-photos">Manager Photos</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-users">Manager Users</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/create-super-agent">Manager Create Super Agent</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-support">Manager Support</a></li>
  <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-totals">Manager Totals</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/create-biller">Admin Create Biller</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/create-biller-category">Admin Create Biller Category</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/billers">Admin Billers</a></li>
  <li *ngIf="user && user.admin"><a routerLink="/biller-categories">Admin Biller Categories</a></li>
</ul> -->
