import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  id?: string;
  user?: any;
  payment?: any;

  fromPayments = false;
  fromAdminPayments = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, private modalService: NgbModal, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.id = route.snapshot.params['id'];
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { payments?: boolean, adminPayments?: boolean };
    if (state && state.payments) {
      this.fromPayments = state.payments;
    }
    if (state && state.adminPayments) {
      this.fromAdminPayments = state.adminPayments;
    }
    
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe(params => { 
      this.payment = null;
      this.id = params.get('id')!; 
      this.httpClient.get('/api/payments/' + this.id,  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.payment = result.payment;
      })
    });
  }

  pay() {
    this.router.navigate(['/create-booking'],{state:{receiver: this.payment.receiver, sender: this.payment.sender, amount: this.payment.amount, payment: this.payment.id }});
  }

  settle() {
      let body = [ this.id ];
      this.httpClient.post('/api/admin/settle', body, {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        if (result.result === 0) {
          this.payment.settled = true;
        }
      });
      
  }

  back() {
    if (this.fromPayments) {
      this.router.navigate(['/payments']);
    } else if (this.fromAdminPayments) {
      this.router.navigate(['/admin-payments']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
