import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ImageService } from 'src/app/services/image.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeclineMessagesComponent } from 'src/app/dialogs/decline-messages/decline-messages.component';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent {

  bucket?: string;
  id?: string;
  userId?: string;

  user?: any;

  kyc?: any;

  message = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    public dialog: MatDialog
    ) {

      this.id = route.snapshot.params['id'];
      this.bucket = ImageService.kycBucket;

      this.userService.currentUserSubject.subscribe((user) => {
        this.user = user;
    });
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe(params => {
      this.kyc = null;
      this.id = params.get('id')!;
      let splits = this.id.split('!');

      this.httpClient.get('/api/kycs/' + splits[0] + '!' + splits[1],  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.kyc = result.kyc;
      })
    });
  }

  approve() {
    this.httpClient.post('/api/kycs/' + this.id, {approved:true},  {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result)=> {
      this.router.navigate(['/kycs']);
    });
  }

  disapprove() {
    let data;
    const dialogRef = this.dialog.open(DeclineMessagesComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.message = result.message;

      if (this.message != '') {
        data = { approved: false, message: this.message };

        this.httpClient.post('/api/kycs/' + this.id, data,  {
          headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
        }).subscribe((result)=> {
          this.router.navigate(['/kycs']);
        });
      }
    });
  }

  back() {
   // const date = formatDate(this.kyc.submitteddate, 'yyyy-MM-dd', 'en');
    this.router.navigate([`/kycs`]);
  }

  home() {
    this.router.navigate(['/']);
  }

}
