import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  isAuthenticated?: boolean;
  user: any;

  balances?: any[];

  constructor(private router: Router, private userService: UserService, private authService: AuthService) { 
    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        this.balances = Object.keys(this.user.balances).map(key => ({currency: key, amount: this.user.balances[key]}));
        if (this.user.balance == undefined) {
          this.user.balance = 0;
          /*this.balances = this.user.balances.map((balance) => {
            return {currency: balance}
          })*/
        }
      }
    );
  }

  ngOnInit(): void {
  }

  send() {
    this.router.navigate(['/send']);
  }

  receive() {
    this.router.navigate(['/receive']);
  }

}
