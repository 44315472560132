<!-- <div class="d-lg-flex flex-row justify-content-around mb-1" [ngClass]="{'d-none':user.agent}" style="margin-left: auto; margin-right: auto">
    <app-wallet></app-wallet>
    <app-cash *ngIf="user.agent"></app-cash>
</div> -->
<!-- <div *ngIf="isAuthenticated && user.agent">
    <ul class="d-lg-none nav-pills justify-content-center">
        <li ngbNavItem>
            <a ngbNavLink>Wallet</a>
            <ng-template ngbNavContent>
                <app-wallet></app-wallet>
            </ng-template>
        </li>
        <li *ngIf="user.agent" ngbNavItem>
            <a ngbNavLink>Cash</a>
            <ng-template ngbNavContent>
                <app-cash></app-cash>
            </ng-template>
        </li>
    </ul>
    <div class="d-lg-none"></div>
</div> -->
<div *ngIf="isAuthenticated" class="min-width" style="display: flex; overflow-x: hidden; height: calc(100vh - 80px);">
  <div style="width:270px;">
      <app-side-menu></app-side-menu>
  </div>
  <div class="router-container">
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="!isAuthenticated">
  <app-login></app-login>
</div>
<!--
<div class="container">
    <div *ngIf="isAuthenticated">
        <router-outlet></router-outlet>
    </div>
</div>
<div *ngIf="!isAuthenticated">
    <app-login></app-login>
</div> -->
