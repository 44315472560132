<button class="back-button" (click)="back()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back
</button>

<h2>Agents</h2>
<table *ngIf="agents">
    <tr>
        <th>Id</th>
        <th>Name</th>
    </tr>
    <tr *ngFor="let agent of agents">
        <td><a [routerLink]="'/agents/' + agent.id">{{ agent.id }}</a></td>
        <td>{{ agent.fullName }}</td>
    </tr>
</table>
<div *ngIf="!agents">
    Loading Agents
</div>
