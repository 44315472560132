<button class="back-button" (click)="back()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back
</button>

<h2>Bookings</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadBookings()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="bookings && bookings.length == 0">
    No Bookings yet for this day!
</div>
<table *ngIf="bookings && bookings.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Type</th>
        <th>Sender</th>
        <th>Receiver</th>
        <th>Amount</th>
        <th>Status</th>
        <th>Payment</th>
    </tr>
    <tr *ngFor="let booking of bookings" (click)="openBooking(booking.id)" style="cursor: pointer">
        <td>{{ booking.date | date:'medium' }}</td>
        <td>{{ booking.type }}</td>
        <td>{{ booking.senderDetail?booking.senderDetail.fullName:'' }}</td>
        <td>{{ booking.receiverDetail?booking.receiverDetail.fullName:booking.name }}</td>
        <td>{{ booking.amount | currency:booking.currency }}</td>
        <td>{{ booking.complete?'Complete':'' }}{{ booking.cancelled?'Cancelled':'' }}</td>
        <td><a *ngIf="booking.payment" [routerLink]="'/payments/' + booking.payment">Yes</a></td>
    </tr>
</table>
<div *ngIf="!bookings">
    Loading Bookings
</div>
