<div class="dashboard-container">
  <mat-grid-list cols="8">
    <mat-grid-tile colspan="6" class="left-container">
      <img src="assets/img/dashboard/welcome-person.svg">
      <div class="banner"></div>

      <div class="row quickaccess">
        <span class="cover">
          <h4>Quick Access</h4>
          <h5>Edit</h5>
        </span>
        <div class="cover">
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content routerLink="/admin-totals">
              <label>Totals</label>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content routerLink="/kycs">
              <label>KYC</label>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content routerLink="/admin-bookings">
              <label>Bookings</label>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-content>
              <label>+ Add</label>
            </mat-card-content>
          </mat-card>
        </div>
    </div>

    <div class="row cover overviewtransact">
      <div class="overview">
        <span class="cover">
          <h4>Overview</h4>
          <h5>Year <mat-icon style="font-size: 16px;height: fit-content;">keyboard_arrow_down</mat-icon></h5>
        </span>
        <div>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content>
              <span>
                <p>Total Downloads</p>
                <h2>XXX</h2>
              </span>
              <div class="graph"></div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content>
              <span>
                <p>Total Users</p>
                <h2 style="color: #F1652F;">XXX</h2>
              </span>
              <div class="graph"></div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content>
              <span>
                <p>Total Agents</p>
                <h2 style="color: #224292;">XXX</h2>
              </span>
              <div class="graph"></div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="transact">
        <span class="cover">
          <h4>Transaction</h4>
          <h5>See all</h5>
        </span>
        <ul>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
          <li>lorem ipsum</li>
        </ul>
      </div>
    </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" class="right-container">
      <div class="row income">
        <span class="cover">
          <h4>Income</h4>
          <h5>Week <mat-icon style="font-size: 16px;height: fit-content;">keyboard_arrow_down</mat-icon></h5>
        </span>

       <div class="table row"></div>
   <!--   <div class="incomeoverview row">
        <span class="cover" >
          <h6>Income Overview</h6>
        </span>
        <div>
          <mat-card *ngIf="user && (user.admin || user.manager)">
            <mat-card-content>
              <span>
                <p>Total Agents</p>
                <h2 style="color: #224292;">XXX</h2>
              </span>
              <div class="graph"></div>
            </mat-card-content>
          </mat-card>
        </div>
      </div> -->
    </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
