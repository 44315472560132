import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-kycs',
  templateUrl: './admin-kycs.component.html',
  styleUrls: ['./admin-kycs.component.scss']
})
export class AdminKycsComponent {
  displayedColumns = ['ID', 'Name', 'Account Type', 'Status'];
  model?: NgbDateStruct;
  kycs?: any;
  user?: any;
  datepick = new FormControl('');
  selecteddate?: any;
  dataSource = new MatTableDataSource(this.kycs);

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) {
    // let date = new Date();
    this.selecteddate = formatDate(Date(), 'yyyy-MM-dd', 'en');
    // this.model = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
    this.kycs = this.route.snapshot.data['kycs'];
    this.userService.currentUserSubject.subscribe((user: User) => {
      this.user = user;
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadKycs() {
    if(this.datepick.value !== null) {
      this.selecteddate = formatDate(this.datepick.value, 'yyyy-MM-dd', 'en');
      this.httpClient.get(`/api/kycs?ymd=${this.selecteddate}`, {
            headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
          }).subscribe((results) => {
            this.kycs = results;
            this.dataSource = this.kycs;
          });
    }

    // if (this.model) {
    //   let year = this.model.year;
    //   let month = this.model.month>9?this.model.month:'0'+this.model.month;
    //   let day = this.model.day>9?this.model.day:'0'+this.model.day;
    //   this.httpClient.get('/api/kycs?ymd=' + year + '-' + month + '-' + day, {
    //     headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    //   }).subscribe((results) => {
    //     this.kycs = results;
    //   });
    // }
  }

  openKyc(kyc: any) {
    this.router.navigate(['/kyc/' + kyc.userId + '!' + kyc.id]);
  }

  back() {
    this.router.navigate(['/']);
  }
}
