<!-- <button class="btn btn-primary" (click)="back()">&lt; Back</button> -->
<h3 class="page-title">KYC Application</h3>
<div class="card-container">
  <mat-card>
    <mat-card-content>
      <label>Total</label>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div class="circle" style="background-color: #FFE1A6;">
        <mat-icon>home</mat-icon>
      </div>
      <span>
        <h2>xx</h2>
        <label>Pending</label>
      </span>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div class="circle" style="background-color: #AFDCB9;">
        <mat-icon>home</mat-icon>
      </div>
      <span>
        <h2>xxx</h2>
        <label>Approved</label>
      </span>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div class="circle" style="background-color: #FABAB6;">
        <mat-icon>home</mat-icon>
      </div>
      <span>
        <h2>xx</h2>
        <label>Declined</label>
      </span>
    </mat-card-content>
  </mat-card>
</div>


<div class="accessories-container">
  <mat-form-field appearance="outline">
    <mat-label>Search</mat-label>
    <input matInput placeholder="Search here..." (keyup)="applyFilter($event)">
    <mat-icon matIconPrefix>search</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input matInput [matDatepicker]="picker" [(ngModel)]="selecteddate" [formControl]="datepick" (ngModelChange)="loadKycs()">
    <mat-datepicker-toggle  matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>


<!--
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadKycs()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div> -->


<!-- <div *ngIf="kycs && kycs.length == 0">
    No Payments yet for this day!
</div> -->

<div class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.userDetail.id}} </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.userDetail.fullName}} </td>
    </ng-container>

    <ng-container matColumnDef="Account Type">
      <th mat-header-cell *matHeaderCellDef> Account Type </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="element.approved == undefined" style="background-color:#FFE1A6;color:#F69230">pending</p>
        <p *ngIf="element.approved == true" style="background-color:#AFDCB9;color:#1A9C36">approved</p>
        <p *ngIf="element.approved == false" style="background-color:#FABAB6;color:#F13B2F">declined</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;" (click)="openKyc(row)"></tr>

    <tr class="mat-row empty-state" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <img src="assets/illustration/empty-table-illustration.svg">
        <p>Oops! It's empty.</p>
      </td>
    </tr>
  </table>
</div>


<!--

<table *ngIf="kycs && kycs.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Name</th>
        <th>Type</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let kyc of kycs" (click)="openKyc(kyc)" style="cursor: pointer">
       <td><a [routerLink]="'/kyc/' + kyc.userId + '!' + kyc.id">{{ kyc.id }}</a></td>
        <td>{{ kyc.date | date:'medium' }}</td>
        <td>{{ kyc.userDetail.fullName }}</td>
        <td>{{ kyc.type }}</td>
        <td>{{ kyc.approved == undefined?'PENDING':(kyc.approved?'APPROVED':'DENIED') }}</td>
    </tr>


</table> -->
