import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { SendComponent } from './components/send/send.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { BookingAgentComponent } from './components/booking-agent/booking-agent.component';
import { BookingComponent } from './components/booking/booking.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ReceiveComponent } from './components/receive/receive.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MainComponent } from './components/main/main.component';
import { CreateBookingComponent } from './components/create-booking/create-booking.component';
import { AgentsComponent } from './components/agents/agents.component';
import { SetCashComponent } from './components/set-cash/set-cash.component';
import { BookingGivecashComponent } from './components/booking-givecash/booking-givecash.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SendScanComponent } from './components/send-scan/send-scan.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { OffersComponent } from './components/offers/offers.component';
import { OfferComponent } from './components/offer/offer.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { CreatePaymentComponent } from './components/create-payment/create-payment.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentComponent } from './components/payment/payment.component';
import { CreateBillerComponent } from './components/create-biller/create-biller.component';
import { BillersComponent } from './components/billers/billers.component';
import { PayBillComponent } from './components/pay-bill/pay-bill.component';
import { CreateSuperAgentComponent } from './components/create-super-agent/create-super-agent.component';
import { SuperAgentsComponent } from './components/super-agents/super-agents.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { CashComponent } from './components/cash/cash.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InFlightComponent } from './components/in-flight/in-flight.component';
import { AdminCashInComponent } from './components/admin-cash-in/admin-cash-in.component';
import { CashInComponent } from './components/cash-in/cash-in.component';
import { CashInGcashComponent } from './components/cash-in-gcash/cash-in-gcash.component';
import { CashInAgentComponent } from './components/cash-in-agent/cash-in-agent.component';
import { CashOutComponent } from './components/cash-out/cash-out.component';
import { CashOutAgentComponent } from './components/cash-out-agent/cash-out-agent.component';
import { CreateBillerCategoryComponent } from './components/create-biller-category/create-biller-category.component';
import { BillerCategoriesComponent } from './components/biller-categories/biller-categories.component';
import { PayBillCategoriesComponent } from './components/pay-bill-categories/pay-bill-categories.component';
import { KycComponent } from './components/kyc/kyc.component';
import { AdminKycsComponent } from './components/admin-kycs/admin-kycs.component';
import { CreateKycComponent } from './components/create-kyc/create-kyc.component';
import { AgentComponent } from './components/agent/agent.component';
import { AdminBookingsComponent } from './components/admin-bookings/admin-bookings.component';
import { AdminPaymentsComponent } from './components/admin-payments/admin-payments.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AdminUserComponent } from './components/admin-user/admin-user.component';
import { AdminCashOutComponent } from './components/admin-cash-out/admin-cash-out.component';
import { AdminSupportComponent } from './components/admin-support/admin-support.component';
import { SelfieComponent } from './components/selfie/selfie.component';
import { AdminPhotosComponent } from './components/admin-photos/admin-photos.component';
import { AdminTotalsComponent } from './components/admin-totals/admin-totals.component';
import { BookingSenderagentcancelComponent } from './components/booking-senderagentcancel/booking-senderagentcancel.component';
import { BookingReceiveragentcancelComponent } from './components/booking-receiveragentcancel/booking-receiveragentcancel.component';
import { BookingCancelComponent } from './components/booking-cancel/booking-cancel.component';
import { BookingFeedbackForRoleComponent } from './components/booking-feedback-for-role/booking-feedback-for-role.component';
import { AdminTransactionsComponent } from './components/admin-transactions/admin-transactions.component';
import { AdminUserCashInsComponent } from './components/admin-user-cash-ins/admin-user-cash-ins.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//angular material imports
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeclineMessagesComponent } from './dialogs/decline-messages/decline-messages.component';

@NgModule({
    declarations: [
        AdminBookingsComponent,
        AdminCashInComponent,
        AdminCashOutComponent,
        AdminKycsComponent,
        AdminPaymentsComponent,
        AdminPhotosComponent,
        AdminSupportComponent,
        AdminTotalsComponent,
        AdminTransactionsComponent,
        AdminUserCashInsComponent,
        AdminUsersComponent,
        AdminUserComponent,
        AgentComponent,
        AgentsComponent,
        AppComponent,
        BillersComponent,
        BillerCategoriesComponent,
        BookingAgentComponent,
        BookingCancelComponent,
        BookingComponent,
        BookingFeedbackForRoleComponent,
        BookingGivecashComponent,
        BookingsComponent,
        BookingSenderagentcancelComponent,
        BookingReceiveragentcancelComponent,
        CashComponent,
        CashInComponent,
        CashInGcashComponent,
        CashInAgentComponent,
        CashOutComponent,
        CashOutAgentComponent,
        CreateBillerCategoryComponent,
        CreateBillerComponent,
        CreateBookingComponent,
        CreateKycComponent,
        CreatePaymentComponent,
        CreateSuperAgentComponent,
        DashboardComponent,
        HomeComponent,
        InFlightComponent,
        KycComponent,
        LoginComponent,
        LogoutComponent,
        MainComponent,
        NotFoundComponent,
        OffersComponent,
        OfferComponent,
        PayBillCategoriesComponent,
        PayBillComponent,
        PaymentsComponent,
        PaymentComponent,
        ReceiveComponent,
        SelfieComponent,
        SendComponent,
        SendScanComponent,
        SetCashComponent,
        SuperAgentsComponent,
        TimelineComponent,
        WalletComponent,
        SideMenuComponent,
        DeclineMessagesComponent,

    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        GoogleMapsModule,
        MatCheckboxModule,
        HttpClientModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        MatCardModule,
        MatSidenavModule,
        MatTableModule,
        NgbModule,
        QRCodeModule,
        ReactiveFormsModule,
        ZXingScannerModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
