import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  user: any;
  isAuthenticated?: boolean;
  currentDate: any;

  constructor (
    private authService: AuthService,
    private userService: UserService,
    ) {

      console.log(Date());
     this.currentDate = formatDate(new Date(), 'longDate', 'en');

    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );
  }

}
