<button class="back-button" (click)="back()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back
</button>

<h2>Cash-In</h2>
<ul class="cards">
    <li><a routerLink="/cash-in/gcash"><img src="/assets/img/dashboard/cash_in.svg">G-Cash</a></li>
    <li><a routerLink="/cash-in/agent"><img src="/assets/img/dashboard/cash_in.svg">Agent Pick-up</a></li>
</ul>
