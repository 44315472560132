<div class="login-container">
    <div class="login-style">
        <img src="assets/img/logo/fslogo_square.svg">
        <form [formGroup]="form" (ngSubmit)="login()">
            <div class="input-group">
                <input
                    type="text"
                    formControlName="login"
                    class="input-control"
                    placeholder="Email or Phone Number"
                    maxlength="32"
                />
            </div>
            <div class="input-group">
                <input
                    [type]="(showPassword ? 'text' : 'password')"
                    formControlName="password"
                    class="input-control password"
                    placeholder="Password"
                    maxlenght="16"
                />
                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="showPassword = !showPassword"
                    >
                    <i [class]="(showPassword ? 'bi bi-eye' : 'bi bi-eye-slash')" style="font-size:15px;"></i>

                </button>
            </div>
            <a><label>Forget your password?</label></a>
            <button type="submit" class="fastsend-btn button">Login</button>
        </form>
    </div>
    <!--
    <div class="register-form">
        <form [formGroup]="registerForm" (ngSubmit)="register()" style="margin: 0 auto; width: 400px">
            <div class="form-group">
                <label>Name</label>
                <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                />
            </div>
            <div class="form-group">
                <label>Email</label>
                <input
                    type="text"
                    formControlName="email"
                    class="form-control"
                />
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input
                    type="text"
                    formControlName="phone"
                    class="form-control"
                />
            </div>
            <div class="form-group">
                <label>Password</label>
                <input
                    type="password"
                    formControlName="password"
                    class="form-control"
                />
            </div>
            <div class="p-4 text-center">
                <button type="submit" class="btn btn-primary">Register</button>
            </div>
        </form>
    </div> -->
</div>
