import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent{
  @ViewChild('panelRef') panelRef !: MatExpansionPanel;

  view = 'web';
  user: any;
  isAuthenticated?: boolean;
  canExpand: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService
    ) {
      this.authService.isAuthenticatedSubject.subscribe(
        (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        }
      );

      this.userService.currentUserSubject.subscribe(
        (user) => {
          this.user = user;
          if (this.user.cash == undefined) {
            this.user.cash = 0;
          }
        }
      );
  }

  preventPanelExpansion(event: MouseEvent): void {
    this.panelRef.close();
  }
}
