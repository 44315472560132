<h3 class="page-title">Application Details</h3>
<button class="back-button" (click)="back()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back
</button>

<div *ngIf="!kyc">
  Loading KYC
</div>

<mat-grid-list *ngIf="kyc" cols="2" rowHeight="fit">
  <mat-grid-tile colspan="2">
    <div class="container-1">
      <span>
        <mat-icon class="avatar">person_outline</mat-icon>
        <div>
          <span>
            <h2>{{ kyc.userDetail.fullName }}&nbsp;&nbsp;</h2>
            <p *ngIf="kyc.approved == undefined" style="background-color:#FFE1A6;color:#F69230">pending</p>
            <p *ngIf="kyc.approved == true" style="background-color:#AFDCB9;color:#1A9C36">approved</p>
            <p *ngIf="kyc.approved == false" style="background-color:#FABAB6;color:#F13B2F">declined</p>
          </span>
          <span>
            <mat-icon> location_on</mat-icon>
            <p class="label">Address Here &bull;&nbsp;Submitted on {{ kyc.submittedDate | date:'longDate' }} {{ kyc.submittedDate | date:'shortTime' }}</p>
          </span>
        </div>

      </span>
      <span *ngIf="kyc.approved == undefined">
        <button mat-flat-button style="background-color: #1A9C36;" (click)="approve()">Approve</button>
        <button mat-flat-button style="background-color: #F13B2F;" (click)="disapprove()">Decline</button>
      </span>
      <span *ngIf="kyc.approved == false"> <p> {{ kyc.message }}</p></span>

    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="container-2">
      <h3>Personal Information</h3>
      <ul>
        <li> <p class="label">First Name</p> {{ this.kyc.fields.firstName }} </li>
        <li> <p class="label">Last Name</p> {{ this.kyc.fields.lastName }} </li>
        <li> <p class="label">Email</p> {{ this.kyc.fields.email }} </li>
        <li> <p class="label">Date of Birth</p> {{ this.kyc.fields.dateOfBirth | date: 'longDate'}} </li>
        <li> <p class="label">ID Type</p> {{ this.kyc.fields.idType }} </li>
        <li> <p class="label">ID Number</p> {{ this.kyc.fields.idNumber }} </li>
      </ul>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="container-3">
      <h3>Uploaded Files</h3>
      <ul>
        <li *ngFor="let upload of kyc.uploads"><a [href]="bucket + upload ">{{ upload }}</a></li>
      </ul>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<!--
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
    <div *ngIf="kyc" style="width:100%; max-width: 700px">

        <div>KYC Id: {{ kyc.id }}</div>
        <div>User: {{ kyc.userId }}</div>
        <div>Status: {{ kyc.approved == undefined?'PENDING':kyc.approved?'APPROVED':'DECLINED' }}</div>
        <div *ngIf="kyc.message">Message: {{ kyc.message }}</div>
        <div>User: <a [routerLink]="'/admin-users/' + kyc.userId">{{ kyc.userDetail.fullName }}</a></div>
        <div>Type: {{ kyc.type }}</div>
        <div>Date Started: {{ kyc.date }}</div>
        <div>Date Submitted: {{ kyc.submittedDate }}</div>
        <h3>Fields</h3>
        <div *ngFor="let field of kyc.fields  | keyvalue">{{ field.key }}: {{ field.value }}</div>
        <h3>Uploads</h3>
        <div *ngFor="let upload of kyc.uploads"><a [href]="bucket + upload ">{{ upload }}</a></div>

        <button class="btn btn-primary" (click)="approve()">Approve</button>
        <button class="btn btn-primary ml-2" (click)="disapprove()">Disapprove</button>
        Message: <input type="text" [(ngModel)]="message">
    </div>
    <div *ngIf="!kyc">
        Loading KYC
    </div>
</div> -->
