<header *ngIf="isAuthenticated" class="min-width">
  <nav class="navbar">
   <a routerLink="/"><img class="logo" src="../assets/img/logo/fs-logo.svg"></a>
      <ul class="nav-list">
        <li><a><i class="bi bi-bell"></i></a></li>
        <li><a><i class="bi bi-gear"></i></a></li>
        <li><a><i class="bi bi-person-circle"></i></a></li>
        <li><a routerLink="/logout">LOG OUT</a></li>
      </ul>
 </nav>
</header>
<!-- <div style="display: flex;">
  <div style="width:200px; background-color: violet;">
      <app-side-menu></app-side-menu>
  </div>
  <div style="width:100%; background-color: green;">

  </div>
</div> -->
<router-outlet></router-outlet>
<!-- <div *ngIf="isAuthenticated" class="sticky">
  <div style="display: flex; overflow-x: scroll">
    <div *ngFor="let booking of bookings" class="card m-1 booking" (click)="openBooking(booking)" [ngClass]="booking.deleted?'deleted':''">
        <div>
            <span *ngIf="booking.user != booking.sender && booking.user == user.id" class="badge bg-success">Creator</span>
            <span *ngIf="booking.sender == user.id" class="badge bg-success">Sender</span>
            <span *ngIf="booking.senderAgent == user.id" class="badge bg-info">Sender Agent</span>
            <span *ngIf="booking.receiverAgent == user.id" class="badge bg-warning">Receiver Agent</span>
            <span *ngIf="booking.receiver == user.id" class="badge bg-danger">Receiver</span>
        </div>
        <div *ngIf="booking.name">Receiver: {{ booking.name }}</div>
        <div *ngIf="booking.amount">Amount: {{ booking.amount | currency:booking.currency }}</div>
        <app-timeline [events]="[{title:'Sending Agent',done: booking.senderAgent != null},{title:'Receiving Agent', done: booking.receiverAgent != null},{title:'Cash Picked Up',done: booking.cashPickedUp},{title:'Cash Dropped Off',done: booking.cashDroppedOff}]"></app-timeline>
    </div>
    <div *ngIf="!bookings">
    Loading Bookings
    </div>
</div>
</div> -->


<!-- <footer>
  <div class="wrapper">

    <a class="logo" href="#">Chase</a>
    <div class="footer-left">
      <p class="copyright">Copyright 2021 &copy; Chase</p>
      <p class="footer-links"><a href="#">Contact Us</a> I <a href="#">Terms &amp; Conditions</a> I<a href="#">Privacy</a></p>
    </div>
    <div class="footer-center">
      <a href="#" class="social facebook">Facebook</a>
      <a href="#" class="social twitter">Facebook</a>
      <a href="#" class="social google">Facebook</a>
    </div>
    <div class="clear"></div>
  </div>
</footer> -->
