<button class="back-button" (click)="back()">
  <mat-icon>keyboard_arrow_left</mat-icon>
  Back
</button>

<h2>Cash-Out</h2>
<ul class="cards">
    <!--li><a routerLink="/cash-out/gcash"><img src="/assets/img/dashboard/cash_in.svg">G-Cash</a></li-->
    <li><a routerLink="/cash-out/agent"><img src="/assets/img/dashboard/cash_in.svg">Agent Drop-off</a></li>
</ul>
