<div class="dialog-container">
  <div [formGroup]="messageForm">
    <h4>Why is KYC declined?</h4>
    <mat-form-field>
      <mat-label>Enter Reason</mat-label>
      <input matInput #input maxlength="50" formControlName="message">
      <mat-hint align="end">{{input.value.length}}/50</mat-hint>
    </mat-form-field>
    <div>
      <button mat-button mat-dialog-close>Cancel</button>
      <button [disabled]="!messageForm.valid" mat-button [mat-dialog-close]="messageForm.value" style="color: #fff; background-color: #F1652F;">
        Submit
      </button>
    </div>
  </div>
</div>
