import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminBookingsComponent } from './components/admin-bookings/admin-bookings.component';
import { AdminCashInComponent } from './components/admin-cash-in/admin-cash-in.component';
import { AdminCashOutComponent } from './components/admin-cash-out/admin-cash-out.component';
import { AdminPaymentsComponent } from './components/admin-payments/admin-payments.component';
import { AdminSupportComponent } from './components/admin-support/admin-support.component';
import { AdminUserComponent } from './components/admin-user/admin-user.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { AgentComponent } from './components/agent/agent.component';
import { AgentsComponent } from './components/agents/agents.component';
import { BillerCategoriesComponent } from './components/biller-categories/biller-categories.component';
import { BillersComponent } from './components/billers/billers.component';
import { BookingAgentComponent } from './components/booking-agent/booking-agent.component';
import { BookingComponent } from './components/booking/booking.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { CashInAgentComponent } from './components/cash-in-agent/cash-in-agent.component';
import { CashInGcashComponent } from './components/cash-in-gcash/cash-in-gcash.component';
import { CashInComponent } from './components/cash-in/cash-in.component';
import { CashOutAgentComponent } from './components/cash-out-agent/cash-out-agent.component';
import { CashOutComponent } from './components/cash-out/cash-out.component';
import { CreateBillerCategoryComponent } from './components/create-biller-category/create-biller-category.component';
import { CreateBillerComponent } from './components/create-biller/create-biller.component';
import { CreateBookingComponent } from './components/create-booking/create-booking.component';
import { CreateKycComponent } from './components/create-kyc/create-kyc.component';
import { CreatePaymentComponent } from './components/create-payment/create-payment.component';
import { CreateSuperAgentComponent } from './components/create-super-agent/create-super-agent.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { KycComponent } from './components/kyc/kyc.component';
import { AdminKycsComponent } from './components/admin-kycs/admin-kycs.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MainComponent } from './components/main/main.component';
import { OffersComponent } from './components/offers/offers.component';
import { PayBillCategoriesComponent } from './components/pay-bill-categories/pay-bill-categories.component';
import { PayBillComponent } from './components/pay-bill/pay-bill.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ReceiveComponent } from './components/receive/receive.component';
import { SendComponent } from './components/send/send.component';
import { SetCashComponent } from './components/set-cash/set-cash.component';
import { SuperAgentsComponent } from './components/super-agents/super-agents.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminBookingsResolver } from './resolvers/admin-bookings.resolver';
import { AdminPaymentsResolver } from './resolvers/admin-payments.resolver';
import { AdminSupportResolver } from './resolvers/admin-support.resolver';
import { AdminUsersResolver } from './resolvers/admin-users.resolver';
import { AgentsResolver } from './resolvers/agents.resolver';
import { BillerCategoriesResolver } from './resolvers/biller-categories.resolver';
import { BillersResolver } from './resolvers/billers.resolver';
import { BookingsResolverService } from './resolvers/bookings-resolver.service';
import { KycsResolver } from './resolvers/kycs.resolver';
import { OffersResolverResolver } from './resolvers/offers-resolver.resolver';
import { PaymentsResolver } from './resolvers/payments.resolver';
import { SuperAgentsResolver } from './resolvers/super-agents.resolver';
import { SelfieComponent } from './components/selfie/selfie.component';
import { AdminPhotosComponent } from './components/admin-photos/admin-photos.component';
import { AdminPhotosResolver } from './resolvers/admin-photos.resolver';
import { AdminTotalsComponent } from './components/admin-totals/admin-totals.component';
import { AdminTotalsResolver } from './resolvers/admin-totals.resolver';
import { AdminTransactionsComponent } from './components/admin-transactions/admin-transactions.component';
import { AdminTransactionsResolver } from './resolvers/admin-transactions.resolver';
import { AdminUserCashInsComponent } from './components/admin-user-cash-ins/admin-user-cash-ins.component';
import { AdminUserCashInsResolver } from './resolvers/admin-user-cash-ins.resolver';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      { path: 'send', component: SendComponent },
      { path: 'receive', component: ReceiveComponent },
      {
        path: 'offers',
        component: OffersComponent,
        resolve: {
          offers: OffersResolverResolver
        }
      },
      {
        path: 'pay-bill',
        component: PayBillCategoriesComponent,
        resolve: {
          billerCategories: BillerCategoriesResolver
        }
      },
      {
        path: 'pay-bill/:category',
        component: PayBillComponent,
        resolve: {
          billers: BillersResolver
        }
      },
      {
        path: 'create-biller',
        component: CreateBillerComponent
      },
      {
        path: 'billers',
        component: BillersComponent,
        resolve: {
          billers: BillersResolver
        }
      },
      {
        path: 'billers/:category',
        component: BillersComponent,
        resolve: {
          billers: BillersResolver
        }
      },
      {
        path: 'create-biller-category',
        component: CreateBillerCategoryComponent
      },
      {
        path: 'biller-categories',
        component: BillerCategoriesComponent,
        resolve: {
          billerCategories: BillerCategoriesResolver
        }
      },
      {
        path: 'cash-in',
        component: CashInComponent,
      },
      {
        path: 'cash-in/gcash',
        component: CashInGcashComponent,
      },
      {
        path: 'cash-in/agent',
        component: CashInAgentComponent,
      },
      {
        path: 'cash-out',
        component: CashOutComponent,
      },
      {
        path: 'cash-out/agent',
        component: CashOutAgentComponent,
      },
      {
        path: 'admin-cash-in',
        component: AdminCashInComponent
      },
      {
        path: 'admin-cash-out',
        component: AdminCashOutComponent
      },
      {
        path: 'admin-bookings',
        component: AdminBookingsComponent,
        resolve: {
          bookings: AdminBookingsResolver
        }
      },
      {
        path: 'admin-payments',
        component: AdminPaymentsComponent,
        resolve: {
          payments: AdminPaymentsResolver
        }
      },
      {
        path: 'admin-usercashins',
        component: AdminUserCashInsComponent,
        resolve: {
          userCashIns: AdminUserCashInsResolver
        }
      },
      {
        path: 'admin-transactions',
        component: AdminTransactionsComponent,
        resolve: {
          transactions: AdminTransactionsResolver
        }
      },
      {
        path: 'admin-photos',
        component: AdminPhotosComponent,
        resolve: {
          photos: AdminPhotosResolver
        }
      },
      {
        path: 'admin-totals',
        component: AdminTotalsComponent,
        resolve: {
          totals: AdminTotalsResolver
        }
      },
      {
        path: 'admin-users',
        component: AdminUsersComponent,
        resolve: {
          users: AdminUsersResolver
        }
      },
      {
        path: 'admin-users/:id',
        component: AdminUserComponent
      },
      {
        path: 'admin-support',
        component: AdminSupportComponent,
        resolve: {
          support: AdminSupportResolver
        }
      },
      {
        path: 'create-super-agent',
        component: CreateSuperAgentComponent
      },
      {
        path: 'super-agents',
        component: SuperAgentsComponent,
        resolve: {
          superAgents: SuperAgentsResolver
        }
      },
      { path: 'create-booking', component: CreateBookingComponent },
      {
        path: 'bookings',
        component: BookingsComponent,
        resolve: {
          bookings: BookingsResolverService
        }
      },
      { path: 'bookings/:id', component: BookingComponent },
      { path: 'b/:id', component: BookingComponent },
      { path: 'bookings/:id/agents/:id', component: BookingAgentComponent },
      {
        path: 'payments',
        component: PaymentsComponent,
        resolve: {
          payments: PaymentsResolver
        }
      },
      { path: 'payments/:id', component: PaymentComponent },
      { path: 'agent', component: AgentComponent },
      { path: 'setcash', component: SetCashComponent },
      { path: 'create-payment', component: CreatePaymentComponent },
      { path: 'create-kyc', component: CreateKycComponent },
      { path: 'selfie', component: SelfieComponent },
      { path: 'kyc/:id', component: KycComponent },
      {
        path: 'kycs',
        component: AdminKycsComponent,
        resolve: {
          kycs: KycsResolver
        }
      },
      {
        path: 'agents',
        component: AgentsComponent,
        resolve: {
          agents: AgentsResolver
        }
      }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
