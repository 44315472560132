import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cash-in-gcash',
  templateUrl: './cash-in-gcash.component.html',
  styleUrls: ['./cash-in-gcash.component.scss']
})
export class CashInGcashComponent implements OnInit {


  
  form: UntypedFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('0'),
    reference: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  cashIn() {
    let body = {
      amount: +this.form.value.amount,
      receiver: this.form.value.receiver,
      type: 'GCASH',
      reference: this.form.value.reference,
    };
    this.httpClient.post('/api/cashin', body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      //if (result.result === 0) {
      //}
    });
  }

  back() {
    this.router.navigate(['/cash-in']);
  }

}
