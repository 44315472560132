import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-decline-messages',
  templateUrl: './decline-messages.component.html',
  styleUrls: ['./decline-messages.component.scss']
})
export class DeclineMessagesComponent {

  constructor(
    public dialogRef: MatDialogRef<DeclineMessagesComponent>,
    private fb: FormBuilder
  ) {}

  messageForm = this.fb.group({
    message: ['', Validators.required]
  });

  onNoClick(): void {
    this.dialogRef.close();
  }
}
